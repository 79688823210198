import React from 'react';

const RingLoader = () => {
  return (
    <div className="lds-ring bdy-wrp dark-blue login-page">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default RingLoader;
