import React, { FC } from 'react';

import heroImage from '@/assets/images/sc-meta-image1.jpg';
import { isBrowser } from '@/common/utils';

interface IMetaTags {
  title?: string;
  description?: string;
}

const MetaTags: FC<IMetaTags> = ({
  title = 'SuperChamps | Welcome to the $CHAMP token challenge',
  description = '$CHAMP is the native token of the Super Champs Universe, a rich anime-inspired world that extends across video games, social media, digital & print comic books, must-own merch and more.',
}) => {
  const url = isBrowser()
    ? `${window.location.protocol}://app.superchamps.com${window.location.pathname}`
    : '';

  const image = `https://app.superchamps.com${heroImage}`;
  return (
    <>
      <script src="https://telegram.org/js/telegram-web-app.js"></script>

      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Open Graph data */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="https://app.superchamps.com/" />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={description} />

      {/* Twitter Summary card images must be at least 120x120px */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" content="app.superchamps.com" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:type" content="image/jpeg" />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="630" />

      {/* <meta name="p:domain_verify" content="18aa2f400ae0ab7ee8fd5ddebbec543f" /> */}
    </>
  );
};

export default MetaTags;
