import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { isBrowser } from '@/common/utils';
import MetaTags from '@/components/meta-tags';
import RingLoader from '@/components/rings/rings';
import { useAuth } from '@/context/auth-context';

import '@/assets/scss/app.scss';

function IndexPage() {
  const { user } = useAuth();

  useEffect(() => {
    if (isBrowser()) {
      // navigate(
      //   `/racket-rampage/${
      //     window.location.search ? window.location.search : ''
      //   }`,
      // );
      navigate(
        `/seasons/${window.location.search ? window.location.search : ''}`,
      );
    }
  }, [user]);

  return <RingLoader />;
}

export function Head() {
  return (
    <>
      <MetaTags />
    </>
  );
}

export default IndexPage;
